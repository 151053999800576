

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}
console.log('js !');

$(document).ready(function(){

    $('.homepage-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2500,
    });

    if(getCookie('cookie')){
        $('#cookie-law').hide();
    }else{
        $('#cookie-law').show();
    }

    $('#accept-law').on('click',function (e) {
        setCookie('cookie','ok',365);
        // console.log(getCookie('cookie'));
        $('#cookie-law').hide();
    });

    // wyrównywanie treści slidera do siatki
    function marginLeft(){
        var slider_content = $(".top-lp .left-lp .text");
        var margin = parseInt($(".header-wrapper > .container").css("margin-left"));
        slider_content.css('margin-left',margin+15);
    }
    marginLeft();

    $(window).on('resize', function(){
        marginLeft();
    });

    // wyświetlanie przycisku miniformularza
    const widget_button = $('#widget-form');
    function checkDistance() {
        var height = $(window).scrollTop();
        var distance = 845;
        if (height > distance) {
            widget_button.show();
        }
        if(height <= distance) {
            widget_button.hide();
        }
    }
    checkDistance();

    $(window).scroll(function () {
        checkDistance();
    });
    $(".widget-link").on('click',function (e) {
        $( "#form-blue-handle" ).toggle( 0, function() {});
    });

    // toggle formluarza po kliku w przycisk
    $(".widget-button, .popup-form").on('click',function (e) {
        $( "#form-blue-handle" ).toggle( 0, function() {});
    });
    $(".close-form-blue").on('click',function (e) {
        $( "#form-blue-handle" ).toggle( 0, function() {});
    });
    $('.current').on('click',function (e){

        var lang = $(this).closest('.language').find('.language-list');
        lang.toggle();

    });
});

