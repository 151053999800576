$(document).ready(function(){
    var faqs=$(".lw_faq_sec");
    var counter=0;
    var singleFaq=null;
    var ID="";
    $.each(faqs, function(k,e) {
        singleFaq=$(e).find(".bar-expand");
        ID= "faq-"+counter;
        $(singleFaq).attr("data-target", "#"+ID);
        $(singleFaq).closest(".lw_faq_sec").find(".lw_faq_answer").attr("id", ID);
        counter++;
    });

});